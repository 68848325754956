import { NgModule } from '@angular/core';
import { Ng2SearchPipe } from './ng2-filter.pipe';
export class Ng2SearchPipeModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
  declarations: [Ng2SearchPipe],
  exports: [Ng2SearchPipe]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function Ng2SearchPipeModule_tsickle_Closure_declarations() {
/** @type {?} */
Ng2SearchPipeModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
Ng2SearchPipeModule.ctorParameters;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
