import { Pipe, PipeTransform, Injectable } from '@angular/core';
export class Ng2SearchPipe implements PipeTransform {
/**
 * @param {?} items object from array
 * @param {?} term term's search
 * @return {?}
 */
transform(items: any, term: string): any {
    if (!term || !items) return items;

    return Ng2SearchPipe.filter(items, term);
  }
/**
 *
 * @param {?} items List of items to filter
 * @param {?} term  a string term to compare with every property of the list
 * 
 * @return {?}
 */
static filter(items: Array<{ [key: string]: any }>, term: string): Array<{ [key: string]: any }> {

    const /** @type {?} */ toCompare = term.toLowerCase();
/**
 * @param {?} item
 * @param {?} term
 * @return {?}
 */
function checkInside(item: any, term: string) {
      for (let /** @type {?} */ property in item) {
        if (item[property] === null || item[property] == undefined) {
          continue;
        }
        if (typeof item[property] === 'object') {
          if (checkInside(item[property], term)) {
            return true;
          }
        }
        if (item[property].toString().toLowerCase().includes(toCompare)) {
          return true;
        }
      }
      return false;
    }

    return items.filter(function (item) {
      return checkInside(item, term);
    });
  }
static decorators: DecoratorInvocation[] = [
{ type: Pipe, args: [{
  name: 'filter',
  pure: false
}, ] },
{ type: Injectable },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function Ng2SearchPipe_tsickle_Closure_declarations() {
/** @type {?} */
Ng2SearchPipe.decorators;
/**
 * @nocollapse
 * @type {?}
 */
Ng2SearchPipe.ctorParameters;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
